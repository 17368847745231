import React from 'react'
import Link from 'gatsby-link'
import './projectCards.css'


const ProjectCard = props => (
    <a href={props.link}><div className="project">
        <div className="projectInfo">
        <Link to={props.link}>    
            <h2>{props.title}</h2>
            <h5>Role</h5>
            <p>{props.role}</p>
            <h5>Year</h5>
            <p>{props.year}</p>
        </Link>
        </div>
        <div id="imgLink"><img src={props.image}/></div>
    </div>
    </a>
)

export default ProjectCard
