import React from 'react'
import Layout from '../components/layout'

import ProjectCard from '../components/projectCards';



const IndexPage = () => (
    <Layout>
      <div className="hero">
        <div className="heroGroup">
          <h1>I'm a designer based in Prague, currently working at <a target="_blank" rel="noopener noreferrer" href="https://www.kiwi.com" class="herolink">Kiwi.com</a>.</h1>
        </div>
        </div>
      <div className="sectionWork">
          {/* <div className="dividers">Work</div> */}
              <ProjectCard
              link="/vsls"
              title="Microsoft Visual Studio Live Share"
              role="UI/UX designer"
              year="2017–2018"
              image={require('../images/VSLS.png')} />
              
              <ProjectCard
              link="/beddr"
              title="Beddr"
              role="UI/UX designer"
              year="2017"
              image={require('../images/beddr.png')} />

              {/* <ProjectCard 
              link="/steam"
              title="Reimagining Steam"
              role="Concept, direction"
              year="2017"
              image={require('../images/steam.png')} /> */}

              <ProjectCard 
              link="/feeln"
              title="Feeln"
              role="UI designer"
              year="2015"
              image={require('../images/feeln.png')} />
             
      </div>
      {/* <div className="sectionPosts">
          <div className="dividers">Posts</div>
          <PostCell
            date="16·05·2018"
            posttitle="Reimagining Steam"
            publication="STRV Design Insights"
            postlink="https://medium.com/p/e46821ac59d3"/>
          
          <PostCell
            date="03·05·2017"
            posttitle="UI Kits and saving time while prototyping"
            publication="STRV Design Insights"
            postlink="https://medium.com/p/60c9d1fb8de0"/>
          
          <PostCell
            date="15·08·2016"
            posttitle="How the Designers’ Digest came to be"
            publication="STRV Design Insights"
            postlink="https://medium.com/p/93f3d04ac662"/>
            

      </div> */}
      <div className="sectionNowplaying">
          <div className="dividers">Now Playing – Sep 2021</div>
            <div className="nowPlaying">
            <p><a target="_blank" rel="noopener noreferrer" href="https://music.apple.com/cz/album/the-idyll-opus-i-vi/1569218632">Adjy - <b>The Idyll Opus (I-VI)</b></a></p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://music.apple.com/cz/album/aphelion/1570655864">Leprous - <b>Aphelion</b></a></p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://music.apple.com/cz/album/pink-noise/1557922475">Laura Mvula - <b>Pink Noise</b></a></p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://music.apple.com/cz/album/flor-de-biznaga/1384646153">Ampersan - <b>Flor de Biznaga</b></a></p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://music.apple.com/cz/album/%E3%82%A8%E3%83%B3%E3%82%BF%E3%83%BC%E3%83%86%E3%82%A4%E3%83%8A%E3%83%BC/1579315959">Masayoshi Oishi - <b>Entertainer</b></a></p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://music.apple.com/cz/album/etemen-%C3%A6nka/1541410988">Dvne - <b>Etemen Ænka</b></a></p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://music.apple.com/cz/album/out-here-in-there/1449905244">Sidsel Endresen & Bugge Wesseltoft - <b>Out Here. In There.</b></a></p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://music.apple.com/cz/album/persona-1/1573389610">Seiko Oomori - <b>PERSONA #1</b></a></p>
            </div>
      </div>
    </Layout>
)

export default IndexPage
